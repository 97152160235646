<template>
  <div class="content">
    <p>乐享盲盒用户隐私政策</p>
    <div>
      乐享盲盒由安徽信启信息科技有限公司（注册地址：
      安徽省合肥市蜀山区潜山路东侧蔚蓝商务港D座902(，联系方式：4000551301）,以下简称“乐享盲盒”或“我们”运营。
      您的个人信息安全对我们来说至关重要，我们承诺会对您的个人信息和其它数据进行严格保密，并严格按照本隐私政策所阐述的内容处理您的个人信息。我们会根据您的同意和其它可处理您个人信息的法律依据收集、使用、存储、共享和转移您的个人信息。本隐私政策将帮助您了解以下内容：
    </div>
    <div>目录</div>
    <div>
      一、引言；<br />
      二、我们处理个人信息的法律依据；<br />
      三、我们如何收集您的个人信息；<br />
      四、我们如何使用您的个人信息；<br />
      五、我们如何共享、转让、公开披露您的个人信息；<br />
      六、您如何访问和控制自己的信息；<br />
      七、自动索引技术的使用；<br />
      八、您的个人信息保护；<br />
      九、本隐私政策的修订；<br />
      十、其它。<br />
      十一、接入第三方SDK列表
    </div>
    <div>
      一、引言

      <br />我们尊重并保护您的隐私，在您使用我们的服务时，为便于我们及时了解并满足您的需求，我们可能会收集和使用您的相关信息。我们希望能够通过本《隐私政策》向您充分解释和说明，在您使用我们的服务时，我们将如何收集、使用、储存和共享您的这些个人信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。《隐私政策》与您使用的平台服务息息相关，我们希望您能够仔细阅读，在需要时，按照《隐私政策》的指引，做出您认为适当的选择。如果您使用或继续使用我们的服务，即意味着同意我们根据《隐私政策》约定收集、使用、储存和共享您的相关信息。如您不同意《隐私政策》中的内容，则应当立即停止使用我们的服务。我们的产品基于
      DCloud uni-app(5
      App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android
      ID/DEVICE_ID/IDFA、SIM 卡 IMSI
      /Mac地址等信息）以提供统计分析服务,并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。详情内容请访问《DCloud用户服务条款》。

      <br />二、我们处理个人信息的法律依据

      <br />我们将依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》（
      GB/T35273-2017）以及其它相关法律法规收集和使用您的个人信息，为您提供产品和/或服务。我们通常只会在征得您同意的情况下收集您的个人信息。在某些情况下，我们可能还会基于法律义务或者履行合同之必需向您收集个人信息，或者可能需要个人信息来保护您的重要利益或其它人的利益。

      <br />三、我们如何收集您的个人信息

      <br />我们会遵循正当、合法、必要的原则，出于本隐私政策所述的以下目的，收集和使用您在使用服务过程中主动提供或因使用本平台产品和
      /或服务而产生的个人信息。如果您不提供相关信息，可能无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。如您继续使用我们的服务，则视为您同意我们通过以下方式获得与您有关的信息并进行存储。
    </div>
    <p>（一）您须授权我们收集和使用您个人信息的情形</p>
    <div>
      收集个人信息的目的在于向您提供产品和
      /或服务，并且保证我们遵守适用的相关法律、法规及其他规范性文件。您有权自行选择是否提供该信息，但多数情况下，如果您不提供，我们可能无法向您提供相应的服务，也无法回应您遇到的问题。这些功能包括：
    </div>
    <div>
      1. 实现网上购物所必须的功能

      <br />（1） 商品信息展示和搜索
      为了让您快速地找到您所需要的商品，我们可能会收集您使用乐享盲盒盲盒产品与
      /或服务的设备信息（包括设备型号、设备标识、操作系统和乐享盲盒盲盒商城的版本信息、语言设置、手机屏幕尺寸及分辨率、运营商信息）、浏览器类型来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。
      您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。

      <br />（2） 下单
      当您准备对您购物车内的商品进行结算时，系统会生成您购买该商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式；您可以另外填写收货人的固定电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。此外，购买物品的清单用于打印发票，并使您能够确定包裹中的物品。
      您在乐享盲盒盲盒商城上购买手机号卡或合约机、充值话费、申请节能补贴等业务时，您还可能需要根据国家法律法规或服务提供方（包括基础电信运营商、移动转售运营商等）的要求提供您的实名信息，这些实名信息可能包括您的身份信息（比如您的身份证、军官证、工作居住证、护照、驾驶证等载明您身份的证件复印件或号码）、您本人的照片或视频、姓名、电话号码等。当您购买全屋定制服务时，我们还会收集您主动提供的房屋地址、姓名、电话、新媒体账号（选填）用于与您联系上门时间和地点，以及户型、房屋状态、采购预算信息用于为您定制方案。这些订单中将可能包含您的设备安装地址（可能是您的家庭地址）等信息。您在购买手机意外险的时候，我们还需要您提供手机
      IMEI。 上述所有信息构成您的 “订单信息”。若您购买的商品或服务属于 乐享盲盒
      盲盒商城自营的，为帮助您实现订单相关服务，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询和展示订单详情以及提供客服咨询与售后服务。若您购买的商品或服务由第三方商家直接提供，且商品的配送、客服与售后服务由此类商家为您提供，则我们会将您的订单信息提供给此类商家来实现此类目的。若第三方商家委托
      乐享盲盒
      盲盒商城提供上述的配送、客服与售后服务，我们也会使用您的订单信息来为您提供此类服务。

      <br />（3） 支付功能 在您下单后，您可以选择 乐享盲盒 盲盒商城的关联方或与 乐享盲盒
      盲盒商城合作的第三方支付机构（包括 乐享盲盒
      盲盒支付、微信支付、支付宝及银联等支付通道，以下称
      “支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。

      <br />（4） 交付产品或服务功能 在当您下单并选择货到付款或在线完成支付后，与 乐享盲盒
      盲盒商城合作的第三方配送公司（包括顺丰等，以下称
      “配送公司”）将为您完成订单的交付。您知晓并同意与 乐享盲盒
      盲盒商城合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。

      <br />（5） 客服与售后功能
    </div>
    <div>
      乐享盲盒 盲盒电话客服和售后功能会使用您的账号信息和订单信息。为保证您的账号安全，
      乐享盲盒
      盲盒呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与
      乐享盲盒
      盲盒客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。
    </div>
    <div>
      3.1 您向我们提供的信息

      <br />1）注册/登录本平台时提供的手机号码。

      <br />2）使用本平台服务或参与平台活动时可能要求提供的身份信息，包括但不限于您的姓名、性别、住址、电子邮箱、电话号码、身份证号、工作信息、学历、婚姻状况、紧急联系人、设备信息、生物特征、运营商账号、密码、手机账单及通讯录等信息。

      <br />3）我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其它功能。若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。

      <br />4)
      基于相机（摄像头）的附加服务：您可在开启相机（摄像头）权限后使用拍摄图片、视频用于评价、分享、客服服务或更换头像。即使您已同意开启相机（摄像头）权限，我们也仅会在您主动点击相应图标或录制视频时通过相机获取照片信息。

      <br />5)
      基于存储的附加服务：您可以在开启存储权限后，使用该功能上传、下载您的照片/图片/视频，以实现更换头像、发表评论、分享内容或与客服沟通提供证明等功能。

      <br />6）您向我们提供上述信息后，我们即有权存储该信息并有权根据《隐私政策》约定使用和共享该信息。
    </div>
    <div>
      3.2我们在您使用服务时获取的信息

      <br />1）当您使用我们的服务时，我们可能会自动收集相关信息并存储为服务日志信息。我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括但不限于SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。

      <br />2）
      为了帮助您更好地使用我们的产品或服务，我们会收集服务过程信息。例如，您使用我们的抽奖信息展示服务时，我们将可能收集您的点击和申请记录。

      <br />3）为提高您使用本平台提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会通过了解一些您的网络使用习惯、您常用的软件信息等手段来判断您账户的风险，并可能会记录一些我们认为有风险的URL。
    </div>
    <div>
      3.3 我们从第三方处获得的信息

      <br />我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的乐享盲盒账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与
      /或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
    </div>
    <div>
      3.4 征得授权同意的例外

      <br />根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：

      <br />1）与国家安全、国防安全直接相关的；

      <br />2）与公共安全、公共卫生、重大公共利益直接相关的；

      <br />3）与犯罪侦查、起诉、审判和判决执行等直接相关的；

      <br />4）出于维护您或其它个人的生命、财产等重大合法权益但又很难得到本人同意的；

      <br />5）所收集的个人信息是您自行向社会公众公开的；

      <br />6）从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；

      <br />7）根据您的要求签订和履行合同所必需的；

      <br />8）用于维护本平台的产品和/或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；

      <br />9）法律法规规定的其它情形。
    </div>
    <div>
      四、我们将如何使用收集的信息

      <br />您知悉并同意，我们有权将在向您提供服务的过程之中所收集的信息作下列用途：

      <br />4.1
      向你提供您使用的包括注册、抽奖信息展示服务、抽奖技术服务、抽奖权益发放服务等各项服务，并维护、改进这些服务；

      <br />4.2
      在我们提供服务时用于身份验证、客户服务、安全防范、存档和备份用途，确保我们向您提供的产品和服务的安全性；

      <br />4.3 通过您授权的手机位置信息，为您提供更便捷的本平台网点客服服务。

      <br />4.4 评估、改善我们的广告投放和其它促销及推广活动的效果；

      <br />4.5 邀请您参与有关我们服务的调查；

      <br />4.6 经您许可的其它用途；

      <br />4.7 具体使用方式包括但不限于：

      <br />1）为了能够使用第三方提供的外卖、团购服务，在您乐享盲盒第三方页面时，我们需要您授权我们读取您的地理位置信息；

      <br />2）为了向您提供更好的服务，在您享受我们为您提供的服务填写身份信息时，我们需要您授权我们使用您的姓名、手机号等个人信息去依法设立的第三方查询机构查询并存储您的行为记录等信息。

      <br />3）当您使用乐享盲盒内置的外卖、酒店、电影票、团购等服务时，我们可能会收集位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）。

      <br />4）当您首次安装或打开乐享盲盒APP时，我们可能会收集您手机设备的MAC地址,IMEI,ANDROID
      ID，用于流量变现，消息通知，设备兼容性分析等方面。

      <br />五、我们如何共享、转让、公开披露您的个人信息

      <br />5.1
      共享我们对您的信息承担保密义务。除以下情形外，未经您的同意，我们及我们的关联公司不会与任何第三方共享您的个人信息：

      <br />1）在获得您的明确同意后，我们会与其它方共享您的个人信息。

      <br />2）根据有关法律法规要求向有权的仲裁机构或司法、行政、立法等权力机关提供您的个人资料和信息。

      <br />3）提供给我们的关联方、合作方（包括但不限于合作机构，下同）以用于完成我们对您提供的服务（包括但不限于对您的个人资料和信息进行核实、对您的信用情况进行评估等），我们只会共享完成服务所必要的个人信息，且受本隐私政策中所声明目的的约束。

      <br />4）我们及关联公司及相关合作伙伴、第三方服务商可能定期或不定期向您提供或通知有关产品、服务或相关活动的信息，您同意接收上述信息。

      <br />5）提供给您通过我们提供的服务达成交易的交易方。
      <br />6）其它经您许可的情形。以下列举了具体的授权合作伙伴，并提供了该第三方的隐私政策链接，我们建议您阅读该第三方的隐私政策：

      <br />（
      1）消息推送服务供应商：由每日互动股份有限公司提供推送技术服务，我们可能会将您的设备平台、设备厂商及品牌、设备型号及系统版本、设备识别码、设备序列号等设备信息，应用列表信息、网络信息以及位置相关信息提供给每日互动股份有限公司，用于为您提供推送技术服务。我们在向您推送消息时，我们可能会授权每日互动股份有限公司进行链路调节，相互促活被关闭的SDK推送进程，保障您可以及时接收到我们向您推送的消息。详细内容请访问《个推用户隐私政策》。

      <br />（
      2）广告、媒体：我们可能会将收集到的信息共享给广告、媒体以及我们的其它授权合作伙伴，用于包括优化广告投放和提升营销效果等商业化用途。对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其它任何相关的保密和安全措施来处理用户信息。

      <br />5.2 转让

      <br />我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：

      <br />1）在获得您的明确同意后，我们会向其它方转让您的个人信息；

      <br />2）在本产品服务提供者发生合并、收购或破产清算情形，或其它涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。

      <br />5.3 公开披露

      <br />我们可能基于以下目的披露您的个人信息：

      <br />1）遵守适用的法律法规等有关规定；

      <br />2）遵守法院判决、裁定或其它法律程序的规定；

      <br />3）遵守相关政府机关或其它法定授权组织的要求；

      <br />4）我们有理由确信需要遵守法律法规等有关规定；

      <br />5）为执行相关服务隐私政策或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其它用户或雇员的人身财产安全或其它合法权益合理且必要的用途。

      <br />1）遵守适用的法律法规等有关规定；

      <br />5.4 以下情形中，共享、转让、公开披露您个人信息无需事先征得您的授权同意：

      <br />1）与国家安全、国防安全有关的；

      <br />2）与公共安全、公共卫生、重大公共利益有关的；

      <br />3）与犯罪侦查、起诉、审判和判决执行等有关的；

      <br />4）出于维护您或其它个人的生命、财产等重大合法权益但又很难得到本人同意的；

      <br />5）您自行向社会公众公开的个人信息；

      <br />6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。

      <br />5.5
      根据法律规定，共享、转让已经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。

      <br />六、您如何访问和控制自己的信息

      <br />6.1
      您可以在使用我们服务的过程中，访问、修改和删除您提供的注册信息和其它个人信息，也可按照通知指引与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。例如，若您在使用地理位置相关服务时，希望停止共享您的地理位置信息，您可通过手机定位关闭功能、软硬件服务商及通讯服务提供商的关闭方式停止共享，建议您仔细阅读相关指引。

      <br />
      6.2
      当您发现我们除了关于您的个人信息有错误时，您有权要求我们做出更正或者补充。您可以通过本隐私政策中提及的方式提出更正或补充申请。

      <br />6.3
      您可以通过本隐私政策中提及的方式删除您的部分个人信息。在以下情形中，您可以向我们申请删除您的个人信息：

      <br />1）如果我们处理个人信息的行为违反了法律法规；

      <br />2）如果我们在未征得您明确的同意下收集、使用您的个人信息；

      <br />3）如果我们处理个人信息的行为严重违反了与您的约定；

      <br />4）如果您不再使用我们的产品或服务，或您主动注销了账号；

      <br />5）如果我们永久不再为您提供产品或服务。

      <br />6.4
      在您访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障帐号的安全。

      <br />6.5
      您可以通过联系我们的客服提交注销账户的申请，我们将会在30个工作日内为您销户。在您注销账户后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息或使其匿名化处理。

      <br />6.6
      请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。
      <br />七、 自动索引技术的使用

      <br />为了更好的提供服务，我们会使用自动索引和类似的技术来提供我们的服务，并协助收集数据。您同意我们使用这类技术获得您浏览第三方平台所产生的个人数据信息，我们将使用这些信息来分析您的偏好以改进我们的服务。并且这些信息能够提升我们对您的评价，使您可以获得我们提供的更高层级的服务。

      <br />八、 您的个人信息保护

      <br />我们非常重视您的个人信息安全。我们将采取不低于一般行业惯例对于通过您的授权所获取的信息进行保护，并以一切商业上的合理努力确保这些信息的安全。我们会严格要求我们的员工履行保密及安全义务，如果未能履行这些义务，其会被追究法律责任或被终止与本产品的合作关系。通常，我们的员工或与平台合作的第三方并不会查看在平台系统中存储的用户信息，当我们得知您可能违反了平台的产品及服务条款时，为了及时提醒您以免您留下不良信用记录，我们会根据本《隐私政策》或您与我们另行达成的约定使用您的信息。
      基于防火墙或其它安全软件可能发生故障，或按照当前商业上可以采取的安全手段也难以避免或及时消除的故障及破坏，将可能导致您的信息遭到外部访问、窃取或删除，此等情形下我们不承担相应的责任。因不可抗力所导致的用户资料及信息泄露（包含但不限于黑客攻击、第三方导致的系统缺陷等），我们不承担相应的责任。您在我们平台的账号和密码等账户信息是非常重要的，请妥善保管。使用您的账号、密码等账户信息登录平台进行的操作视为您个人的操作，您应当承担该等操作产生的一切法律后果。如果您因为自身的保管不善、委托他人使用或者其它个人原因导致您权益受损，我们将不承担相关责任。

      <br />九、 本隐私政策的修订

      <br />我们将根据相关法律、法规、政策的要求及提供服务的需要不时地修改本政策并于平台首页发布，您应当注意本政策的变更。若您在本政策公告变更后继续使用本服务，表示您已充分阅读、理解并接受修改后的政策内容，也将遵循修改后的政策使用本平台的服务；同时就您在隐私政策和具体规则修订前通过本平台进行的交易及其效力，视为您已同意并已按照本隐私政策及有关规则进行了相应的授权和追认。

      <br />十、其它

      <br />本《隐私政策》未尽事项按照平台现有及不时发布的各项规则执行。如《隐私政策》中的任何一条或多条被确认为无效，该无效条款并不影响本隐私政策其它条款的效力。因履行本隐私政策所产生的争议应当通过友好协商解决；如协商不成，则任意一方均可向本协议签订地（石家庄市桥西区）所在的人民法院起诉。
      十一、接入第三方SDK列表

      <br />SDK名称：奇虎360(com.ak.torch.shell) SDK用途：用户广告展示，用户流量变现。
      可能获取的个人信息类型：设备标识符、系统版本、设备屏幕信息、网络信息、设备品牌及型号、SIM卡序列、MAC地址
      --------------------- SDK名称：广点通;腾讯社交(com.qq.e.ads, com.qq.e.comm)
      SDK用途：用户广告展示，用户流量变现。
      可能获取的个人信息类型：设备标识符、系统版本、设备屏幕信息、网络信息、设备品牌及型号、SIM卡序列、MAC地址
      官网链接： https://e.qq.com/optout.html ---------------------
      SDK名称：个推;个推推送(com.igexin.sdk) SDK用途：用于自家产品推广或活动通知
      可能获取的个人信息类型：设备标识符、系统版本、设备屏幕信息、网络信息、设备品牌及型号、SIM卡序列、MAC地址
      --------------------- SDK名称：个推;个数应用统计;个像(com.getui)
      SDK用途：用于自家产品推广或活动通知
      可能获取的个人信息类型：设备标识符、系统版本、设备屏幕信息、网络信息、设备品牌及型号、SIM卡序列、MAC地址
      --------------------- SDK名称：阿里weexSDK (com.taobao )
      SDK用途：uni-app基础模块默认集成，用于渲染uniapp的vue页面引擎
      可能获取的个人信息类型：存储的个人文件
      调用的设备权限：读取外置存储器、写入外置存储器 官网链接： http://doc.weex.io/zh
      --------------------- SDK名称：fresco图片库 (com.facebook.fresco SDK)
      用途：uni-app基础模块默认集成，用于nvue页面加载图片使用可能获取的个人信息类型：存储的个人文件
      调用的设备权限：读取外置存储器、写入外置存储器 官网链接： https://www.fresco-cn.org/
      --------------------- SDK名称：x5内核 (com.tencent.tbs、com.tencent.smtt)
      SDK用途：x5内核渲染webview 可能获取的个人信息类型：IMEI、openid
      调用的设备权限：读写外置存储器、读取手机状态和身份 官网链接：
      https://x5.tencent.com/docs/privacy.html --------------------- SDK名称：友盟统计
      (com.uc.crashsdk、com.efs、com.umeng)
      SDK用途：统计用户在某些页面的点击或访问行为，配合后端数据分析做出用户体验改善方案
      可能获取的个人信息类型：设备信息（IMEI、ANDROID_ID、DEVICE_ID、IMSI）、应用已安装列表、网络信息
      官网链接： http://docs.getui.com/privacy --------------------- SDK名称：uni-AD
      SDK用途：基础广告，用户广告展示，用户流量变现。
      可能获取的个人信息类型：设备品牌、型号、操作系统版本、OAID、分辨率、IMEI、android
      ID、SIM 卡 IMSI
      信息、应用名称、应用包名、应用版本号、网络信息、应用安装列表、位置信息 隐私链接：
      https://doc.dcloud.net.cn/markdown-share-docs/1d821cdd3cdf2681045ec4be94bc8404/
      --------------------- SDK名称：快手 (com.kwad.sdk)
      SDK用途：增强广告，用户广告展示，用户流量变现。
      可能获取的个人信息类型：IMEI、openid、位置信息 隐私链接：
      https://www.kuaishou.com/about/policy --------------------- SDK名称：优量汇
      (com.qq.e) SDK用途：增强广告,用户广告展示，用户流量变现。
      可能获取的个人信息类型：IMEI、openid、位置信息 隐私链接：
      https://imgcache.qq.com/gdt/cdn/adn/uniondoc/ylh_sdk_privacy_statement.html
      --------------------- SDK名称：穿山甲 (com.bytedance.sdk.openadsdk)
      SDK用途：增强广告,用户广告展示，用户流量变现。
      可能获取的个人信息类型：IMEI、openid、位置信息 隐私链接：
      https://www.pangle.cn/privacy/partner --------------------- SDK名称：Sigmob
      (com.sigmob.windad) SDK用途：增强广告，用户广告展示，用户流量变现。
      可能获取的个人信息类型：IMEI、openid、位置信息
    </div>
    <div>联系我们</div>
    <div>
      如您对本隐私政策或您个人信息的相关事宜有任何问题、建议，请通过访问我们的移动端在线客服系统与我们联系；或给我们来信，公司名称：安徽信启信息科技有限公司，注册地址：安徽省合肥市蜀山区潜山路东侧蔚蓝商务港D座902
      ，信息保护责任人：俞鑫铎，联系电话：4000551301 .
      本隐私政策版本更新日期为2024年4月1日，将于2024年4月1日正式生效。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.content {
  font-size: 0.34rem;
  padding: 0.2rem;
  letter-spacing: 0.01rem;
  word-break: break-all;
  p {
    font-size: 0.37rem;
    font-weight: bold;
  }
}
</style>
